@import '../../../assets/sass/main.scss';

.requestTable {
    position: relative;
    transition: height 0.15s linear;

    @include respond(bigScreen) {
        width: 80%;  
    }
    @include respond(personal) {
        width: 85%;  
    }

    @include respond(smalls) {
        width: 95%;  
    }

    // @include respond(laptop) {
    //     width: 100%;  
    // }  

    
     
}


@import '../../../../assets/sass/main.scss';

.iconButton {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1.5rem 1rem;
    cursor: pointer;
    transition: ease-in-out .4s;
    position: relative;
    opacity: 0.65;

    i {
        font-size: 2.2rem;
        margin-bottom: 0.5rem;        
    }
        
    span {
        margin: 0 0.5rem;
        font-size: 1rem;     
    }
    
    &:hover > .triangle {
        border-right: 12px solid $color-white;
    }
    
    &:hover {
        opacity: 1;
    }
    
    .triangle {  
        position: absolute;
        right: 0;
        width: 0px;
        top: calc(50% - 20px);
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid $background-color;
        transition: ease-in-out .35s;
    }     
}

.imgBox {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 0.5rem;

    img {
        display: block;
        width: 100%
    }
}

.active .iconButton{
    opacity: 1;

    .triangle {
        border-right: 12px solid $color-white;
    }
}

a {
    color: $color-white;
    
    &:hover {
        color: $color-white;
    }
}


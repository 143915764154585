@import '../../../../assets/sass/main.scss';

.requests {
    .requestsbox {
        display: flex;
        background: $color-almost-white;
        border-top: 2px solid $color-light-green;
        border-right: 2px solid $color-light-green;
        border-left: 2px solid $color-light-green;
        font-size: 0.875rem;
        justify-content: space-between;

        @include respond(laptop) {
            flex-wrap: wrap;
        }

        &:nth-child(2n) {         
            background: $color-white;
        }
    
        h6 {
            font-size: 0.875rem;
            font-weight: 600;
            text-decoration: underline;
        }
    
        .requestsboxTitle {
            @include helpdeskCSS;
            width: 45%;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 8rem;
            }
        }
        
        .requestsboxInfo {
            @include helpdeskCSS;
            width: 25%;
            
            .name {
                font-weight: 600;
            }
        }
    
        .requestsboxButton {
            @include helpdeskCSS;
            width: 10%;
            transition: all 0.2s ease-in-out;
        }
       
        .requestsboxIconDiv {
            background: $color-light-green;    
            border-radius: 20%;
            padding: 1rem;
            margin: 1rem;
            font-size: 1.5rem;  
            font-weight: 300;
            color: $color-white;                          
        }                
       
        .requestsboxStatus {
                width: 10px;
                background-color: $color-green;
        }

    }

    .timeline {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0.7rem 0 0 1rem;
        margin-bottom: 2rem;
        position: relative;

        .timelineReport, .question {
            display: flex;
            align-self: center;
            height: 30px;
            margin-top: 0.8rem;
        }

        .buttons {
            padding-left: 2rem;
            

            button {
                margin-right: 1.5rem;
            }
        }
        
    }

    .dot {
        margin-right: 1rem;
        position: relative;
        display: block;
        width: 18px;
        height: 18px;
        border: 2px solid $color-light-green;
        background-color: $color-orange;
        border-radius: 50%;

        &::before {
            content: '';
            display: block;
            width: 2px;
            top: -40px;
            position: relative;
            height: 27px;
            margin: 13px auto 0;
            background-color: $color-light-green;
        }
 
    }

}






@import '../../../assets/sass/main.scss';

.sideBar {
    min-height: 100vh;
    width: 8.125rem;
    background-color: $background-color;
    position: relative;
    z-index: 1000;

    @include respond(tab) {
        display: none;
    }

    &-bottom {
        width: 100%;
        text-align: center;
        position: sticky;
        top: calc(100% - 140px);
        display: inline-block;

        span {
            font-size: 0.875rem;
        }

        #toggle {
            position: absolute;
            bottom: 0;
            left: 8rem;
    
            h6 {
                font-size: 0.875rem;
            }
        }
    }
    
    a {
        display: block;

        &:hover {
            text-decoration: none;
        }        
    }
}
@import "../../../../assets/sass/main.scss";

.linkButton {
    color: $font-color;
    border: none;
    margin: 0.5rem 3rem 0rem 0rem;
    background-color: transparent;
    transition: all .2s;
    border-bottom: 4px solid $color-white;
    font-size: 1.5rem;

    @include respond(laptop) {
        font-size: 1.2rem;
        margin-right: 1.2rem;
        border-bottom: 1px solid $color-white;
    }

    @include respond(tab) {
        font-size: 1rem;
        margin-right: 1rem;
        border-bottom: 3px solid $color-white;
    }

    &:hover {
        color: $color-light-green;
        border-bottom: 4px solid $color-light-green;
        text-decoration: none;
    }    
}

.active {
   border-bottom: 4px solid $color-light-green; 
}
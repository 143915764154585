@import '../abstracts/variables';

@mixin headerCSS {
    color: $font-color;
    width: calc(100% - 130px);

    @include respond(tab) {
        width: 100%;
    }
    
    &-header {
        border-bottom: 2px solid $color-light-green;
        padding: 1.9rem 7rem 0 7rem;
        margin-bottom: 1.5rem;

        @include respond(laptop) {
            padding: 1.9rem 0rem 0 3rem; 
        }

        // @include respond(tab) {
        //     padding: 1.9rem 0rem 0 3rem;
        // }
       
        
        &-heading {
            font-size: 1.75rem;
            padding-bottom: 1rem;

            @include respond(laptop) {
                font-size: 1.6rem;
            }

            @include respond(tab) {
                font-size: 1.5rem;
            }
        }
    }
    
    &-table {
        padding: 0 7rem;

        @include respond(personal) {
            padding-right: 0;
        }

        @include respond(tab) {
            padding: 0 3rem;
        }

    }
}

@mixin helpdeskCSS {
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 0.875rem;
}

// 1em = 16px
@mixin respond($breakpoint) {
    @if $breakpoint == tab {
        @media ( max-width: 36.875em) { @content }; //  590px
    }
    @if $breakpoint == laptop {
        @media ( max-width: 61.99875em) { @content }; // bootstrap size 991.98px
    }

    @if $breakpoint == smalls {
        @media ( max-width: 74.99875em) { @content }; // bootrap size 1199.98px
    }

    @if $breakpoint == personal {
        @media ( max-width: 93.75em) { @content }; // 1500px
    }

    @if $breakpoint == bigScreen {
        @media ( min-width: 93.8125em) { @content }; // 1501px
    }
}
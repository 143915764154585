@import '../../assets/sass/main.scss';

.search {
    font-size: 1rem;
    height: 60px;
    overflow: hidden;
    transition: height 0.2s linear;

    .main {
        display: flex;
        padding-bottom: 1.5rem;
    
        i {
            top: calc( 50% - 10px);
        } 
    
        .filterButton {
            background-color: $color-light-green;
            position: relative;
            color: $color-white;
            display: flex;
            align-items: center;
            border: 2px solid $color-light-green;
            padding-right: 1rem;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            transition: all 0.2s ease-in-out;
    
            &:focus {
                outline: 0; 
            }
    
            &:hover {
                background-color: rgba($color-light-green, 0.3);
                color: $color-light-green;
            }
    
            &:hover .triangle {
                background-color: $color-light-green;
                transition: all 0.2s ease-in-out;
            }    
    
        }  

        .inputs {
            display: flex;
            align-self: center;
        
            input {
                border: 2px solid $color-light-green;
                padding: 0.3rem 2.8rem 0.3rem 0.5rem;
                border-left: none;

                @include respond(smalls) {
                    padding-right: 0;
                }
            }
        }       
        
        .status {
            display: flex;
            align-self: center;
        }
        
        .triangleBox {
            transform: rotate(180deg);
            transform-origin: 50% 66.66%;
            margin: 0 1rem 0 1rem;
            position: relative;
            bottom: 3px; 
            transition: all 0.2s ease-in;       
    
            .triangle {            
                position: relative;
                background-color: $color-white;
                text-align: left;
    
                
            }
            .triangle:before,
            .triangle:after {
                content: '';
                position: absolute;
                background-color: inherit;
            }
            .triangle,
            .triangle:before,
            .triangle:after {
                width:  0.5em;
                height: 0.5em;
                border-top-right-radius: 40%;
            }
            
            .triangle {
                transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
            }
            .triangle:before {
                transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
            }
            .triangle:after {
                transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
            }
    
        }    

    }
    
        
}


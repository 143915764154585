@import "../../assets/sass/main.scss";

.invoicesPage {
    @include headerCSS;

    &-table {
        font-size: 1rem;
        text-align: left;
        padding-right: 30rem;

        @include respond(personal) {
            padding-right: 20rem;  
        }

        @include respond(smalls) {
            padding-right: 10rem;  
        }

        @include respond(laptop) {
            padding-right: 5rem;  
            padding-left: 3rem; 
        }

        @include respond(tab) {
            padding-left: 3rem;  
        }
    }    

    .attachments-p {
        margin: 0;
        padding: 0;
        a {
            color: blue;
        }
    }
}

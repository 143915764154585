@import '../../../../assets/sass/main.scss';

.triangleBox {
    display: flex;
    justify-content: center;
    
    .triangle {
        display: flex;
        position: relative;
        background-color: $color-light-green;
        text-align: left;
        cursor: pointer;
        
    }
    .triangle:before,
    .triangle:after {
        content: '';
        position: absolute;
        background-color: inherit;
    }
    .triangle,
    .triangle:before,
    .triangle:after {
        width:  1em;
        height: 1em;
        border-top-right-radius: 20%;
    }
    
    .triangle {
        transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
    }
    .triangle:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    .triangle:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
}

#TriangleActive {
    transform: rotate(320deg);
    transition: transform 2s ease-in-out;

}


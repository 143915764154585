@import '../../../../assets/sass/main.scss';

.roundButton {
    cursor: pointer;
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;
    display: inline-block;
    background: $color-light-green;
    text-align: center;
    
    i {
        position: relative;
        top: calc( 50% - 13px);
        font-size: 1.5rem;
        color: rgba($color-white, 0.65);
        transition: ease-in-out .15s;
    }

    &:hover > i {
        color: rgba($color-white, 1)
    }
}
@import '../../../../assets/sass/main.scss';
#toggle {
    background-color: $background-color;
    padding: 1.5rem 1rem;
    transform: translateX(-300%);
    transition: transform 0.5s ease-in-out;
    z-index: 5;
    position: relative;
    text-align: center;

    &-info {
        display: flex;
        flex-direction: column;
    }

    &-btn {
        margin-top: 1rem;
        width: 125px;
        display: flex;
        justify-content: space-between;
    }

    button {
        line-height: 1.2rem;
        font-size: 0.7rem;
    }

    &.open {
        transform: none;
    }
}


@import '../../assets/sass/main.scss';

.welcomePage {
    color: $background-color;
    font-size: 2rem;
    margin: 0 auto;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(tab) {
        margin-left: 15px;
    }

    .languageBar {
        position: absolute;
        top: 10px;
        right: 0;
        line-height: 20px;

        button {
            color:  $background-color;
            display: flex;
        }
    }
}
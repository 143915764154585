$color-white: #FFF;
$font-color: #040b0b;
$color-light-grey: #d3d3d3;
$color-grey: #556161;
$color-light-green:  #0f9288; 
$background-color: #084a4f;
$color-red: red;
$color-light-blue: #107691;
$color-green: #26bf81;
$color-orange: #cc9933;
$color-black: #000;
$color-almost-white: #f7f9f9;
$color-start: #26bcbc;

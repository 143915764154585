@import '../../../../assets/sass/main.scss';

.roundButton {
    margin-top: 0.4rem;
    background-color: rgba($color-light-green, 1);
    width: 100%;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: rgba($color-white, 0.9);
    border: none;
    line-height: 2.5rem;
    border-radius: 0.5rem;
    transition: all .2s; 

    &:hover {     
        background-color: rgba($color-light-green, 0.8);  
    }

    &:disabled {
        cursor: no-drop;
    }
}

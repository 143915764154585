@import "../../assets/sass/main.scss";

.helpDeskPage {
    @include headerCSS;

    &-header-links {
        @include respond(tab) {
            display: flex;
            justify-content: space-between;
        }
    }

    &-formBox {
        padding: 0 7rem;
        font-size: 1.125rem;

        @include respond(smalls) {
            padding-right: 0;
        }

        @include respond(laptop) {
            padding: 0 3rem;
        }

     
        form {
            textarea,
            input {
                width: 100%;           
                padding: 0.2rem 0.2rem 0.2rem 0.8rem;
                
            }

            button {
                width: 30%;
            }
        }

        &-subject,
        &-message {
            margin: 0.5rem 0;
            border: 2px solid $color-light-green;
            
            input {
                border: none;
            }
        }

        &-form {
            @include respond(bigScreen) {
                width: 50%;  
            }
            @include respond(personal) {
                width: 70%;  
            }
            @include respond(laptop) {
                width: 100%;  
            }            
        }

        &-subject {
            margin-top: 2rem;
        }
    }
}
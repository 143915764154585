@import '../../../assets/sass/main.scss';

.infoBlock {
    font-size: 0.875rem;
    background: $color-white;
    text-align: left;
    
    
    @include respond(personal) {
        width: 90%
    }

    @include respond(smalls) {
        margin-right: 3rem;
    }

    @include respond(tab) {
        width: 100%;
    }
    

         &:nth-child(2n) {         
         background: $color-almost-white;
       }
 
    .infoBlockSmall, .infoBlockBig {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        // transition: 3s ease-in-out;
    }

    .infoBlockSmall:first-child, .infoBlockBig:first-child {
        border-top: 2px solid $color-light-green;
    }
    

    
    .infoBlockSmall {        
        border-right: 2px solid $color-light-green;
        border-left: 2px solid $color-light-green;
        border-bottom: 2px solid $color-light-green;
        cursor: pointer;

        .information {
            display: flex;
            padding: 1rem 0 1rem 1rem;
            align-items: center;
            width: 100%;

            
            @include respond(laptop) {
                flex-wrap: wrap;
            }

            @include respond(tab) {
                display: block;
                text-align: center;                
            }  

            
    
            .equipment {
                color: $color-grey
            }
    
            .headingOne {
                width: 35%;

                @include respond(smalls) {
                    width: 30%;
                }

                @include respond(laptop) {
                    width: 50%;
                    margin-bottom: 1rem;
                }  
                
                @include respond(tab) {
                    width: 100%;
                    padding: 0.2rem;
                }  
                
                div {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;  
                }
            }
    
            .headingTwo {
                width: 15%;
                padding-left: 3rem;

                @include respond(personal) {
                    margin-right: 2rem;
                }

                @include respond(smalls) {
                    width: 15%;
                } 
                
                @include respond(laptop) {
                    width: 40%;
                    text-align: right;
                }

                @include respond(tab) {
                    width: 100%;
                    padding: 0.2rem;
                    text-align: center;
                }  

            }
            
            .headingThree {
                width: 30%; 

                @include respond(laptop) {
                    width: 50%;
                }

                @include respond(tab) {
                    width: 100%;
                    padding: 0.2rem;
                }  
            }
    
            .headingFour {
                width: 20%;    
                
                @include respond(personal) {
                    text-align: center;
                }

                @include respond(laptop) {
                    width: 40%;
                    text-align: right;
                }

                @include respond(tab) {
                    text-align: center;
                    width: 100%;
                    padding: 0.2rem;
                }  
            }
            
        }
    }
    
        .status {
            width: 10px;
            background-color: $color-green;
        }

    .infoBlockBig  { 
        border-right: 2px solid $color-light-green;
        border-left: 2px solid $color-light-green;
        border-bottom: 2px solid $color-light-green;

        @include respond(tab) {
            overflow: hidden;
        }

        i {
            cursor: pointer;
            font-size: 1rem;
            padding-right: 0.5rem;
        }

        .information {
            padding: 1rem 0 1rem 1rem;
        }
        
    }

    td {
        padding-right: 2rem;

        input {
            padding-right: 1rem;
        }
    }

}

.infoBlock:last-child {
    margin-bottom: 15px;
}
@import '../abstracts/variables';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

// For better responsive. 16px = 1rem. 
html {
    font-size: 100%;
}

body { 
    font-family: 'Montserrat', sans-serif;    
    box-sizing: border-box;
    font-weight: 400;
    letter-spacing: 1px;
    color: $color-white;
}

.home {
    display: flex;
}

#done {
    background-color: $color-green;
}

#working {
    background: $color-orange;
}

#start {
    background: $color-start;
}
@import '../../../assets/sass/main.scss';

.lds-dual-ring {
    width: 64px;
    height: 64px;
    margin: auto;
    padding: 2rem 0;

    &:after {
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid $color-light-green;
        border-color: $color-light-green transparent $color-light-green transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
@import '../../assets/sass/main.scss';

.servicesPage {
   @include headerCSS;

   &-table {
      position: relative;  
      
      @include respond(laptop) {
         padding-left: 3rem;  
      }

      @include respond(tab) {
         padding-left: 3rem;  
      }
     
    }
}
@import '../../assets/sass/main.scss';

.loginPage {
    height: 100vh;
    background-size: cover;
    background-image: linear-gradient(to right top, #0d372b, #0c4037, #0b4944, #0c5252, #0f5b60);

    &-languageBar {
        position: absolute;
        top: 10px;
        right: 0;

        button {
            color: $color-white;
        }
    }

    &-box {
        position: relative;
        top: 35%;
        margin: 0 auto;

        &-logo {
            padding-bottom: 0.5rem;
                
            img {
                width: 100%;
                display: block
            }     
        }

        &-input {
            display: flex;
            margin: 1rem 0;
            line-height: 2.0rem;
            border-radius: 0.5rem;           

            input {
                background-color: $color-white;                
                border-radius: 0.5rem;
                width: 100%;
                font-size: 1.1rem;
                padding-left: 1rem;
                color: $color-grey;
                border: none;       
                letter-spacing: 1px;
                height: 2.5rem;
                transition: all .2s;  
                

                &:focus {
                    color: $color-grey;
                    outline: none;     
                                   
                }

                &:active {
                    border: none;
                }

                &::placeholder {
                    text-transform: uppercase;
                    color: $color-light-grey;
                    letter-spacing: 1px;

                }
            }
        }
    }
}


@import "./assets//sass/main.scss";

.ReactTable {
    border: none;    
    
    .rt {

        &-thead {

            &.-header {
                box-shadow: none;
                border-bottom: 1px solid $color-light-green;
                
            }

            & .rt-th.-sort-asc, .rt-td.-sort-desc {
                box-shadow: inset 0 3px 0 0 rgba($color-light-green,0.6);
            }

            & .rt-th.-sort-desc {
                box-shadow: inset 0 -3px 0 0 rgba($color-light-green,0.6);
            }

            &.rt-th, .rt-td {
                border-right: none;
            }

        } 
    
        &-table {
            font-size: 12px;
        }
    }


    &.-striped .rt-tr.-odd {
        background: rgba($color-light-green,0.1);
    }

    &.-highlight .rt-tbody .rt-tr:not(.-padRow).-odd:hover {
        background: rgba(15, 146, 136, 0.1);
        text-decoration: underline;
    }
   
    &.-highlight .rt-tbody .rt-tr:not(.-padRow).-even:hover {
        background: 0;
        text-decoration: underline;
    }
} 

.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid $color-light-green;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none;
}

.ReactTable .rt-thead.-filters {
    border-bottom: none;   
}

.ReactTable .rt-thead.-filters .rt-th:last-child {
    border-right: none;    
}

.ReactTable .rt-thead .rt-tr {
    text-align: left;
}

// .rt-td {
//     white-space: normal !important
// }
// .ReactTable .rt-th, .ReactTable .rt-td {
//     white-space: unset;
//     word-wrap: break-word;
// }


#blockOpen {
    border-bottom: 1px solid $color-light-grey;
}

#TriangleActive {
    // transition: transform 1ms ease-in-out;
    animation: roll 0.5s;
    animation-fill-mode: forwards;
}

@keyframes roll {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(540deg);
    }
  }

#question_dot {
    background: $color-white; 
}

#activeFilters {
    transform: rotate(360deg);
    transition: all 0.2s ease-in-out;
}

#smoothDropDown {
    height: 320px;

    @include respond(smalls) {
        height: 520px;
    }

    @include respond(laptop) {
        height: 720px;
    }
}

#requestsToggle {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
    transform-origin: 50% 66.66%;
}

.requestsToggle > div:first-child {
    border-bottom: 2px solid $color-light-green;
}
@import '../../assets/sass/main.scss';

.languages {
    display: flex;
    margin: 0.2rem 1rem 0.2rem 0;

    button {
        cursor: pointer;
        margin: 0 0.8rem;
        font-size: 0.8rem;
        border: none;
        background: transparent;
    }
}

.languagesBox {
    border-right: 1px solid;
    border-left: 1px solid;
}


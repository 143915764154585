@import '../../../../assets/sass/main.scss';

.infoBlock {
    font-size: 0.875rem;
    background: $color-white;
    text-align: left;
    
    @include respond (laptop) {
        width: 80%;
    }

        &:nth-child(2n) {         
            background: $color-almost-white;
        }
 
    .infoBlockSmall, .infoBlockBig {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        // transition: 3s ease-in-out;
    }
    
    .infoBlockSmall {        
        border-right: 2px solid $color-light-green;
        border-left: 2px solid $color-light-green;
        border-bottom: 2px solid $color-light-green;
        cursor: pointer;

        .information {
            display: flex;
            padding: 1rem 0 1rem 1rem;
            align-items: center;
            width: 100%;

            @include respond(laptop) {
                flex-wrap: wrap;
            }
    
            .equipment {
                color: $color-grey
            }
    
            .headingOne {
                width: 20%;
                
                div {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;  
                }

                @include respond(laptop) {
                    order: 1;
                    width: 50%;
                }
            }
    
            .headingTwo {
                width: 35%;
                padding-left: 3rem; 
                
                @include respond(laptop) {
                    padding-left: 0;
                    width: 45%;
                    order: 3;
                }
            }
            
            .headingThree {
                width: 35%; 

                @include respond(laptop) {
                    width: 45%;
                    order: 4;
                    text-align: right;  
                }
            }
    
            .headingFour {
                width: 20%;
                text-align: right;  
                padding-right: 3rem;   

                @include respond(laptop) {
                    width: 45%;
                    order: 2;                    
                }
            }

            
            
        }
    }
    
        .status {
            width: 10px;
            background-color: $color-green;
        }

    .infoBlockBig  { 
        border-right: 2px solid $color-light-green;
        border-left: 2px solid $color-light-green;
        border-bottom: 2px solid $color-light-green;

        i {
            cursor: pointer;
            font-size: 1rem;
            padding-right: 0.5rem;
        }

        .information {
            padding: 1rem 0 1rem 1rem;
        }
        
    }

    td {
        padding-right: 2rem;

        input {
            padding-right: 1rem;
        }
    }

}

